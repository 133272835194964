

import React, { useEffect, useState, useMemo } from 'react';
import Loader from './Loader';
import { getAdventurers } from '../api/getAdventurers';
import { loadOpponentsData } from '../api/Opponents';
import { AdventurerType, OpponentType } from '../types/adventurer';
import abi from '../fight.abi.json';
import { useContractWrite } from 'wagmi';
import { getProof } from '../fight';
import { BigNumber } from 'ethers';
import CryptoPrices from './CryptoPrices';
import Lootboxes from './Lootboxes';
import BattleList from './BattleList';
import fetchLootboxes from '../api/getLootboxes';
import { fetchPrices } from '../api/getCryptoPrices';
import { saveAs } from 'file-saver';
import { parse } from 'json2csv';
import RarityItemTable from './RarityItemTable';
import { useAnimaBalance } from '../../dai/context/AnimaBalance.context'; // Import useAnimaBalance
import { useRarityItemBalance } from '../../dai/context/RarityItemBalance.context'; // Use RarityItemBalance context
import { TransactionDataProvider } from '../../dai/context/FightsData.context';
import FightsDataTable from './FightsDataTable';
import { useLpBoost } from '../utils/utils'; // Import useLpBoost
import { setRenownSum } from '../utils/utils'; // Import useLpBoost
import { getAdventurerInfo } from '../utils/utils'; 
import BalanceChart from '../components/BallanceChart'; // Ścieżka do komponentu
import { pollAttackEpoch } from '../api/getAttackEpoch'; 





export default function Adventurers({ address }: { address: string }) {
  const [calculating, setCalculating] = useState(false);
  const [adventurers, setAdventurers] = useState<AdventurerType[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [battles, setBattles] = useState<any>({});
  const [opponents, setOpponents] = useState<OpponentType[]>([]);
  const [fighting, setFighting] = useState<string[]>([]);
  const [loadingOpponents, setLoadingOpponents] = useState(true);
  const [lootboxPrices, setLootboxPrices] = useState<any>({});
  const [cryptoPrices, setCryptoPrices] = useState<any>({});
  const [equip, setEquip] = useState(false);
  const [armory, setArmory] = useState(false);
  const [refreshRarityItems, setRefreshRarityItems] = useState(false);
  const { balance: animaBalance, refresh: refreshAnimaBalance } = useAnimaBalance();
  const { balances: rarityItemBalances, refresh: refreshRarityItemBalances } = useRarityItemBalance();
  const [battleTableRendered, setBattleTableRendered] = useState(false);
  const lpBoostValue = Number(useLpBoost(address)); // Fetch lpBoostValue here
  console.log('Adventurers lpBoostValue',lpBoostValue);

  useEffect(() => {
    refreshAnimaBalance(address);
    refreshRarityItemBalances(address);
  }, [address]);

  const [aov, setAov] = useState(true);
  const [mrdr, setMrdr] = useState(true);
  const [smol, setSmol] = useState(true);
  const [toe, setToe] = useState(true);
  const [life, setLife] = useState(true);
  const [kote, setKote] = useState(true);
  const [onlyReadyForBattle, setOnlyReadyForBattle] = useState(true);


  const [pendingAov, setPendingAov] = useState(aov);
  const [pendingMrdr, setPendingMrdr] = useState(mrdr);
  const [pendingSmol, setPendingSmol] = useState(smol);
  const [pendingToe, setPendingToe] = useState(toe);
  const [pendingLife, setPendingLife] = useState(life);
  const [pendingKote, setPendingKote] = useState(kote);
  const [pendingOnlyReadyForBattle, setPendingOnlyReadyForBattle] = useState(onlyReadyForBattle);
  
  const [transactionMessage, setTransactionMessage] = useState<string | null>(null);

  const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));

  useEffect(() => {
    const fetchAdventurers = async () => {
      const adv = await getAdventurers(address);
      setAdventurers(adv);

      if (adv.length > 0) {
        // First, divide each renown by 1000, then sum them up
        const total = adv.reduce((total: number, adventurer: { renown: number }) => total + adventurer.renown / 1000, 0);
  
        // Then divide the total by the total number of adventurers
        const average = Math.round(total) // adv.length;
  
        // Set the global variable
        setRenownSum(average);
      } else {
        // Handle case with no adventurers (set to 0 or a fallback value)
        setRenownSum(0);
      }
    };

    fetchAdventurers();
  }, [address]);

  useEffect(() => {
    const fetchOpponents = async () => {
      setCalculating(true);
      setLoadingOpponents(true);
      console.log('Fetching opponents...');
      const opData = await loadOpponentsData(address);

      const uniqueOpponents = new Set<string>();
      const opponentsList: OpponentType[] = opData.filter((opponent: OpponentType) => {
        const key = `${opponent.address}-${opponent.tokenId}`;
        if (opponent.tokenId !== 0 && opponent.battlePower !== 0 && opponent.renown !== 0 && !uniqueOpponents.has(key)) {
          uniqueOpponents.add(key);
          return true;
        }
        return false;
      });

      setOpponents(opponentsList);
      setLoadingOpponents(false);
      //setCalculating(false);
      //setBattleTableRendered(false);
      console.log('Opponents fetched');
    };

    fetchOpponents();
  }, [address]);

  useEffect(() => {
    const loadLootboxPrices = async () => {
      const data = await fetchLootboxes();
      const prices = {
        common: data.find(item => item.lootboxId === '1')?.pricePerItem || 0,
        rare: data.find(item => item.lootboxId === '2')?.pricePerItem || 0,
        epic: data.find(item => item.lootboxId === '3')?.pricePerItem || 0,
        legendary: data.find(item => item.lootboxId === '4')?.pricePerItem || 0,
      };
      setLootboxPrices(prices);
    };

    const loadCryptoPrices = async () => {
      const data = await fetchPrices();
      setCryptoPrices(data);
    };

    loadLootboxPrices();
    loadCryptoPrices();
  }, []);

  const { data, writeAsync, error, isError, isLoading } = useContractWrite({
    address: '0x2cfcaff3289142e79173b856293d6128b6bd05c6',
    abi: abi,
    functionName: 'fight',
    mode: 'recklesslyUnprepared',
    overrides: {
      gasLimit: BigNumber.from(2000000).mul(fighting.length)
    }
  });

  const generateFightParams = (selectedIds: string[], equip: boolean) => {
    const ownerAddresses: string[] = [];
    const tokenIds: number[] = [];
    const proofs: string[][] = [];
    const oppAddresses: string[] = [];
    const oppIds: number[] = [];
    const oppProofs: string[][] = [];
    const equipmentIds: number[][] = [];
    const addEquipmentToArmoryIds: number[][] = [];
    const addEquipmentToArmoryAmounts: number[][] = [];

    selectedIds.forEach(id => {
      const battle = battles[id];
      const adv = battle?.adventurer;
      if (adv && battle?.opponent) {
        const advProof = getProof(adv.address);
        const oppProof = getProof(battle.opponent.address);

        proofs.push(advProof);
        ownerAddresses.push(adv.address);
        tokenIds.push(adv.tokenId);
        oppAddresses.push(battle.opponent.address);
        oppIds.push(battle.opponent.tokenId);
        oppProofs.push(oppProof);

        const slots = [
          Number(adv.slot1),
          Number(adv.slot2),
          Number(adv.slot3),
          Number(adv.slot4)
        ];

        if (equip) {
          slots[0] = Number(adv.slot1equip || adv.slot1);
          slots[1] = Number(adv.slot2equip || adv.slot2);
          slots[2] = Number(adv.slot3equip || adv.slot3);
          slots[3] = Number(adv.slot4equip || adv.slot4);
        }

        equipmentIds.push(slots);
        addEquipmentToArmoryIds.push(adv.addEquipmentToArmoryIds || []);
        addEquipmentToArmoryAmounts.push(adv.addEquipmentToArmoryAmounts || []);

        console.log(`Adventurer ${adv.tokenId} addEquipmentToArmoryIds:`, addEquipmentToArmoryIds);
        console.log(`Adventurer ${adv.tokenId} addEquipmentToArmoryAmounts:`, addEquipmentToArmoryAmounts);
      }
    });

    // Combine related data into an array of objects
    const combinedData = ownerAddresses.map((ownerAddress, index) => ({
      ownerAddress,
      tokenId: tokenIds[index],
      proof: proofs[index],
      oppAddress: oppAddresses[index],
      oppId: oppIds[index],
      oppProof: oppProofs[index],
      equipmentId: equipmentIds[index],
      addEquipmentToArmoryId: addEquipmentToArmoryIds[index],
      addEquipmentToArmoryAmount: addEquipmentToArmoryAmounts[index]
    }));

    // Sort the combined data by ownerAddress and then by tokenId
    combinedData.sort((a, b) => {
      if (a.ownerAddress < b.ownerAddress) return -1;
      if (a.ownerAddress > b.ownerAddress) return 1;
      return a.tokenId - b.tokenId;
    });

    // Extract sorted arrays from the combined data
    const sortedOwnerAddresses = combinedData.map(data => data.ownerAddress);
    const sortedTokenIds = combinedData.map(data => data.tokenId);
    const sortedProofs = combinedData.map(data => data.proof);
    const sortedOppAddresses = combinedData.map(data => data.oppAddress);
    const sortedOppIds = combinedData.map(data => data.oppId);
    const sortedOppProofs = combinedData.map(data => data.oppProof);
    const sortedEquipmentIds = combinedData.map(data => data.equipmentId);
    const sortedAddEquipmentToArmoryIds = combinedData.map(data => data.addEquipmentToArmoryId);
    const sortedAddEquipmentToArmoryAmounts = combinedData.map(data => data.addEquipmentToArmoryAmount);

    console.log("Generated Fight Params:", {
      ownerAddresses: sortedOwnerAddresses,
      tokenIds: sortedTokenIds,
      proofs: sortedProofs,
      oppAddresses: sortedOppAddresses,
      oppIds: sortedOppIds,
      oppProofs: sortedOppProofs,
      equipmentIds: sortedEquipmentIds,
      addEquipmentToArmoryIds: sortedAddEquipmentToArmoryIds,
      addEquipmentToArmoryAmounts: sortedAddEquipmentToArmoryAmounts
    });

    return {
      ownerAddresses: sortedOwnerAddresses,
      tokenIds: sortedTokenIds,
      proofs: sortedProofs,
      oppAddresses: sortedOppAddresses,
      oppIds: sortedOppIds,
      oppProofs: sortedOppProofs,
      equipmentIds: sortedEquipmentIds,
      addEquipmentToArmoryIds: sortedAddEquipmentToArmoryIds,
      addEquipmentToArmoryAmounts: sortedAddEquipmentToArmoryAmounts
    };
  };

  const fight = async () => {
    if (writeAsync) {
      const MAX = 50; // Maximum number of records per batch
  
      const { ownerAddresses, tokenIds, proofs, oppAddresses, oppIds, oppProofs, equipmentIds, addEquipmentToArmoryIds, addEquipmentToArmoryAmounts } = generateFightParams(fighting, equip);
  
      const processBatch = async (startIndex: number) => {
        const endIndex = Math.min(startIndex + MAX, ownerAddresses.length);
  
        const batchOwnerAddresses = ownerAddresses.slice(startIndex, endIndex);
        const batchTokenIds = tokenIds.slice(startIndex, endIndex);
        const batchProofs = proofs.slice(startIndex, endIndex);
        const batchOppAddresses = oppAddresses.slice(startIndex, endIndex);
        const batchOppIds = oppIds.slice(startIndex, endIndex);
        const batchOppProofs = oppProofs.slice(startIndex, endIndex);
        const batchEquipmentIds = equipmentIds.slice(startIndex, endIndex);
        const batchAddEquipmentToArmoryIds = addEquipmentToArmoryIds.slice(startIndex, endIndex);
        const batchAddEquipmentToArmoryAmounts = addEquipmentToArmoryAmounts.slice(startIndex, endIndex);
  
        console.log('Batch Armory IDs:', batchAddEquipmentToArmoryIds);
        console.log('Batch Armory Amounts:', batchAddEquipmentToArmoryAmounts);
  
        try {
          // Send the transaction and wait for the user to sign
          const tx = await writeAsync({
            recklesslySetUnpreparedArgs: [[
              batchOwnerAddresses,
              batchTokenIds,
              batchAddEquipmentToArmoryIds,
              batchAddEquipmentToArmoryAmounts,
              batchEquipmentIds,
              batchProofs,
              batchOppAddresses,
              batchOppIds,
              batchOppProofs,
              [1, 2, 3, 4],
              [[65]]
            ]]
          });
  
          // Wait for the transaction to be mined (confirming the state change)
          await tx.wait();
          console.log('Transaction successfully mined, attackEpoch should be updated.');
  
        } catch (error) {
          if (error instanceof Error) {
            // Now `error` is typed as an Error
            if ((error as any).code === 'ACTION_REJECTED') {
              // If using ethers.js or a library with error codes, cast error to `any`
              setTransactionMessage('Tx cancelled.');
            } else {
              setTransactionMessage('Tx failed.');
            }
          } else {
            // Handle the case where error is not an instance of Error
            setTransactionMessage('An unknown error occurred.');
          }
          throw new Error('Tx failed.');
        }
      };
  
      try {
        const batchCount = Math.ceil(ownerAddresses.length / MAX);
  
        for (let i = 0; i < batchCount; i++) {
          await processBatch(i * MAX);
          // Adding a delay between each batch
          await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the delay as needed (e.g., 1000ms = 1 second)
        }
  
        // After processing the last batch, check attackEpoch for the last adventurer
        const lastAdventurerIndex = ownerAddresses.length - 1; // Get the index of the last adventurer
        const lastAdventurer = {
          address: ownerAddresses[lastAdventurerIndex],
          tokenId: tokenIds[lastAdventurerIndex],
        };

        // Poll the attackEpoch of the last adventurer
        await pollAttackEpoch(lastAdventurer.address, lastAdventurer.tokenId);

        setTimeout(() => {
          handleSelectionChange([], false, false);
          setRefreshKey(prevKey => prevKey + 1);
          setRefreshRarityItems(prev => !prev); // Trigger RarityItemTable refresh
          refreshAnimaBalance(address); // Refresh Anima balance
          refreshRarityItemBalances(address);
          setTransactionMessage(null); // Clear transaction message
        }, 5000);
  
      } catch (e) {
        console.log(e);
        setTransactionMessage("Tx failed");
        refreshRarityItemBalances(address);
      }
    }
  };
  
  


  const handleSelectionChange = (ids: string[], equip: boolean, armory: boolean) => {
    setFighting(ids);
    setEquip(equip);
    setArmory(armory);
  };

  const handleReload = () => {
    setAov(pendingAov);
    setMrdr(pendingMrdr);
    setSmol(pendingSmol);
    setToe(pendingToe);
    setLife(pendingLife);
    setKote(pendingKote);
    setOnlyReadyForBattle(pendingOnlyReadyForBattle);
    handleSelectionChange([], false, false); // Clear selections
    setRefreshKey(prevKey => prevKey + 1);
    setRefreshRarityItems(prev => !prev); // Trigger RarityItemTable refresh
    refreshAnimaBalance(address); // Refresh Anima balance
    refreshRarityItemBalances(address);
    setTransactionMessage(null); // Clear transaction message
  };

  const downloadCSV = () => {
    const fields = [
      'address', 'owner' ,'type', 'name', 'tokenId', 'level', 'xp', 'battles', 'strength', 'dexterity',
      'constitution', 'wisdom', 'charisma', 'intelligence', 'lastBattledAt',
      'unbound', 'archetype', 'profession', 'klass', 'renown', 'battlePower',
      'slot1', 'slot2', 'slot3', 'slot4', 'slot1Broken', 'slot2Broken', 'slot3Broken', 'slot4Broken',
      'openSlots', 'attackEpoch'
    ];

    const processedData = opponents
      .map(opponent => ({
        ...opponent,
        lastBattledAt: new Date(opponent.lastBattledAt * 1000).toISOString(),
        renown: Math.floor(opponent.renown / 1000),
        battlePower: Math.floor(opponent.battlePower / 1000),
        type: getAdventurerInfo(opponent.address,opponent.archetype).advType

      }));

    const opts = { fields, delimiter: ';' };
    const csv = parse(processedData, opts);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'opponents.csv');
  };

  const filterCheckboxes = [
    { label: 'AoV', state: pendingAov, setState: setPendingAov, address: '0x747910b74d2651a06563c3182838eae4120f4277' },
    { label: 'MRDR', state: pendingMrdr, setState: setPendingMrdr, address: '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9' },
    { label: 'Smol', state: pendingSmol, setState: setPendingSmol, address: ['0xa7f1462e0ecdeebdee4faf6681148ca96db78777', '0x17dacad7975960833f374622fad08b90ed67d1b5'] },
    { label: 'ToE', state: pendingToe, setState: setPendingToe, address: '0x7480224ec2b98f28cee3740c80940a2f489bf352' },
    { label: 'Life', state: pendingLife, setState: setPendingLife, address: '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4' },
    { label: 'KOTE', state: pendingKote, setState: setPendingKote, address: ['0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9', '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d'] }
  ];

  const visibleCheckboxes = filterCheckboxes.filter(checkbox => {
    if (Array.isArray(checkbox.address)) {
      return checkbox.address.some(addr => adventurers.some(adv => adv.address === addr));
    }
    return adventurers.some(adv => adv.address === checkbox.address);
  });

  return (
    <TransactionDataProvider address={address}>
      <div className="adventurers-wrapper">
        {calculating && !battleTableRendered && <Loader />}
        <div className="top-content">
          <div className="crypto-prices-wrapper">
            <CryptoPrices />
          </div>
          <div className="lootboxes-wrapper">
            <Lootboxes />
          </div>
          <div className="rarity-item-table-wrapper">
            <RarityItemTable refreshTrigger={refreshRarityItems} address={address} /> {/* Add RarityItemTable component */}
          </div>
          <div className="fights-data-table-wrapper">
            <FightsDataTable cryptoPrices={cryptoPrices} address={address} adventurers={adventurers}/>
          </div>
          <div className="chart-wrapper">
            <BalanceChart userAddress = {address} />
          </div>
        </div>
        <div className="bottom-content">
        <div className="buttons-wrapper">
          <button onClick={downloadCSV} disabled={opponents.length === 0 || loadingOpponents}>
            {loadingOpponents ? 'Loading...' : 'Download CSV'}
          </button>
          
          {!loadingOpponents && (
            <div className="filters-wrapper">
              {visibleCheckboxes.length > 1 && (
                <>
                  <div className="filters-text">Set filters</div>
                  {visibleCheckboxes.map(checkbox => (
                    <label key={checkbox.label} className="checkbox-label">
                      {checkbox.label}
                      <input
                        type="checkbox"
                        checked={checkbox.state}
                        onChange={() => checkbox.setState(!checkbox.state)}
                        className="filter-checkbox"
                      />
                    </label>
                  ))}
                </>
              )}

              {/* Always display the 'onlyReadyForBattle' filter and 'press refresh' text */}
              <label key="onlyReadyForBattle" className="checkbox-label">
                only ready for battle
                <input
                  type="checkbox"
                  checked={pendingOnlyReadyForBattle}
                  onChange={() => setPendingOnlyReadyForBattle(!pendingOnlyReadyForBattle)}
                  className="filter-checkbox"
                />
              </label>
              <div className="filters-text">and press refresh</div>

              {/* Reload button */}
              <div onClick={handleReload} className="reload">
                🔄
              </div>
            </div>
          )}

          {fighting.length > 0 && (
            <button className="fight" onClick={fight}>
              {isLoading ? 'Fighting...' : `Fight with ${fighting.length} adventurers`}
            </button>
          )}

          {fighting.length === 0 && <div></div>}
          {isError && transactionMessage && <div>{transactionMessage}</div>}
        </div>


          {!loadingOpponents && (
            <div className="battle-list-wrapper">
              <BattleList
                address={address}
                opponentsData={opponents}
                key={refreshKey}
                cryptoPrices={cryptoPrices}
                lootboxPrices={lootboxPrices}
                onSelectionChange={handleSelectionChange}
                selectedIds={fighting}
                setBattles={setBattles}
                forceRefresh={false}
                adventurers={adventurers}
                setCalculating={setCalculating}
                equip={equip}
                armory={armory}
                onEquipChange={setEquip} // Przekazywanie funkcji
                onArmoryChange={setArmory} // Przekazywanie funkcji
                aov={aov}
                mrdr={mrdr}
                smol={smol}
                toe={toe}
                life={life}
                kote={kote}
                onlyReadyForBattle={onlyReadyForBattle}
                setBattleTableRendered={setBattleTableRendered} // Ensure this line is present
                lpBoostValue={lpBoostValue} // Pass lpBoostValue as a prop
              />
            </div>
          )}
        </div>
        <style jsx>{`
          .adventurers-wrapper {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 60px; /* To ensure it's below the header */
          }

          .top-content {
            display: flex;
            justify-content: center;
            width: 100%;
            z-index: 2;
            margin-bottom: 20px; /* Space below the top content */
            margin-top: -20px; /* Move up by 20px */
          }

         
          .lootboxes-wrapper,
          .fights-data-table-wrapper,
          .chart-wrapper
           { 
            margin: 0 10px;
            font-size: 0.9em; /* Slightly smaller font size */
            white-space: nowrap; /* Prevent content from wrapping */
          }

          .rarity-item-table-wrapper 
          { 
            margin: 0 10px;
            font-size: 0.9em; /* Slightly smaller font size */
            white-space: nowrap; /* Prevent content from wrapping */
          }

          
          .crypto-prices-wrapper  {
           font-size: 0.9em;
            background-color: transparent !important; /* Remove background-color from all elements in CryptoPrices */
            margin: 0 10px;
          }

  
          .bottom-content {
            width: 100%;
            max-width: 1430px;
            display: flex;
            flex-direction: column;
            align-items: flex-start; /* Align items to the left */
            z-index: 1;
          }

          .buttons-wrapper {
            display: flex;
            gap: 10px; /* Space between buttons */
            margin-bottom: 10px; /* Space below the buttons */
          }

          .battle-list-wrapper {
            width: 100%;
            z-index: 1;
          }

          .fights-table-wrapper {
            width: 100%;
            z-index: 1;
            margin-top: 20px; /* Add space above the fights table */
            margin-right: 20px;
          }

          button {
            height: 24px;
            background-color: green;
            color: white;
            border-radius: 4px;
            cursor: pointer;
            z-index: 1;
          }

          .fight {
            height: 24px;
            background-color: red;
            color: white;
            border-radius: 4px;
            cursor: pointer;
            z-index: 1;
          }

          button:hover {
            background-color: darkgreen;
          }

          .fight:hover {
            background-color: darkred;
          }
          
          .filters-wrapper {
            display: flex;
            align-items: center;
            gap: 10px; /* Space between filters */
          }

          .filters-text {
            font-size: 16px;
          }

          .checkbox-label {
            display: flex;
            align-items: center;
            font-size: 14px;
            background-color: rgba(255, 255, 255, 0.25);
            color: black;
            padding: 0px 8px; /* Further reduced height by adjusting padding */
            border-radius: 4px;
            cursor: pointer;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-right: solid 2px black;
            border-bottom: solid 2px black;
            border-left: none;
            border-top: none;
          }

          .filter-checkbox {
            transform: scale(1);
            margin-left: 8px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: rgba(255, 255, 255, 0.5);
            border: none; /* Removed border from checkbox itself */
            border-radius: 4px;
            width: 16px; /* Adjusted size */
            height: 16px; /* Adjusted size */
            cursor: pointer;
            position: relative;
          }

          .filter-checkbox:checked::after {
            content: '✓'; /* Checkmark symbol */
            color: black; /* Checkmark color */
            font-size: 14px; /* Reduced checkmark size */
            position: absolute;
            top: -2px;
            left: 2px;
          }

         
          .reload {
            font-size: 18px;
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.5);
            border-right: solid 2px black;
            border-bottom: solid 2px black;
          }

          .reload:hover {
            cursor:pointer;
          }
        `}</style>
      </div>
    </TransactionDataProvider>
  );
};











































































































