import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { getAdventurers } from '../api/getAdventurers';
import { AdventurerType, OpponentType, Battles, BattlePairType } from '../types/adventurer';
//import ModeSelector from './ModeSelector';
import BattleTable from './BattleTable';
import { PERIPHERALS } from '../constants/constants';
import { calculateBattlePower } from '../api/getAdventurers';
import { useRarityItemBalance } from '../../dai/context/RarityItemBalance.context';
import { 
  calculateWinProbability, 
  calculateLootboxChance, 
  calculateLootBoxValue, 
  calculateAnimaRewardExpected, 
  calculateUsdRewardExpected, 
  calculateRenownRewardExpected,
  selectOpponent
} from '../utils/utils';



type RarityType = 'Common' | 'Rare' | 'Epic' | 'Legendary';

interface NeededPeripheral {
  rarity: RarityType;
  amount: number;
}


interface MaterialNeeded {
  rarity: RarityType;
  amount: number;
}

// Define materials needed per peripheral type
const materialRequirements: Record<RarityType, MaterialNeeded[]> = {
  Common: [{ rarity: 'Common', amount: 2 }],
  Rare: [
    { rarity: 'Common', amount: 4 },
    { rarity: 'Rare', amount: 4 },
  ],
  Epic: [
    { rarity: 'Common', amount: 8 },
    { rarity: 'Rare', amount: 8 },
    { rarity: 'Epic', amount: 6 },
  ],
  Legendary: [
    { rarity: 'Common', amount: 16 },
    { rarity: 'Rare', amount: 16 },
    { rarity: 'Epic', amount: 12 },
    { rarity: 'Legendary', amount: 8 },
  ],
};

// Initialize neededPeripherals as an empty object
const neededPeripherals: Record<RarityType, NeededPeripheral> = {
  Common: { rarity: 'Common', amount: 0 },
  Rare: { rarity: 'Rare', amount: 0 },
  Epic: { rarity: 'Epic', amount: 0 },
  Legendary: { rarity: 'Legendary', amount: 0 },
};

const BattleList = ({
  address,
  opponentsData,
  cryptoPrices,
  lootboxPrices,
  selectedIds,
  onSelectionChange,
  setBattles,
  forceRefresh,
  setCalculating,
  armory,
  onEquipChange,
  onArmoryChange,
  aov,
  mrdr,
  smol,
  toe,
  life,
  kote,
  onlyReadyForBattle,
  setBattleTableRendered,
  lpBoostValue // Receive lpBoostValue as a prop
}: {
  address: string;
  opponentsData: OpponentType[];
  cryptoPrices: any;
  lootboxPrices: any;
  selectedIds: string[];
  onSelectionChange: (ids: string[], equip: boolean, armory: boolean, image: boolean) => void;
  setBattles: (battles: Battles) => void;
  forceRefresh: boolean;
  adventurers: AdventurerType[];
  setCalculating: (calculating: boolean) => void;
  equip: boolean;
  armory: boolean;
  onEquipChange: (checked: boolean) => void;
  onArmoryChange: (checked: boolean) => void;
  aov: boolean;
  mrdr: boolean;
  smol: boolean;
  toe: boolean;
  life: boolean;
  kote: boolean;
  onlyReadyForBattle: boolean;
  setBattleTableRendered: (rendered: boolean) => void;
  lpBoostValue: number | null; // Type for lpBoostValue prop
}) => {

  const [balanceValue, setbalanceValue] = useState<number>(() => {
    const savedBalance = localStorage.getItem('balanceValue');
    return savedBalance ? parseFloat(savedBalance) : 2.00;  // Default to 2.0 if not stored
  });

  const [neededPeripheralsArray, setNeededPeripheralsArray] = useState<NeededPeripheral[]>([]);
  const [materialsNeededArray, setMaterialsNeededArray] = useState<MaterialNeeded[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);

   // Temporary state for slider interaction
   const [tempBalanceValue, setTempBalanceValue] = useState(balanceValue);

  const [image, setImage] = useState(false);
  const [adventurers, setAdventurers] = useState<AdventurerType[]>([]);
  const [materialsFor20Days, setMaterialsFor20Days] = useState<MaterialNeeded[]>([]);

  const [mode, setMode] = useState('2.0');
  const [battlePairs, setBattlePairs] = useState<BattlePairType[]>([]);
  const [checkedOpponentsCount, setCheckedOpponentsCount] = useState<number>(0);
  const usedOpponents = useMemo(() => new Set<string>(), []);
  const [originalAdventurers, setOriginalAdventurers] = useState<AdventurerType[]>([]);
  const { balances: rarityItemBalances, setBalances: setRarityItemBalances } = useRarityItemBalance();
  const [originalBalances, setOriginalBalances] = useState<any>({});
  const [equip, setEquip] = useState<boolean>(true); // Define equip state here
  // Set initial sorting configuration to 'ratio'
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' }>({
    key: 'ratio', // Domyślnie sortuj według "ratio"
    direction: 'ascending', // Możesz ustawić na 'descending' jeśli wolisz
  });

  const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));

  const isSlotOccupied = (slot: String | Number) => {
    if (slot === undefined || Number(slot) === 0) return 0;
    return 1
  }


  const calculateMaterialsFor20Days = (adventurers: AdventurerType[]): MaterialNeeded[] => {
    // Initialize total materials needed
    const totalMaterials: Record<RarityType, number> = {
      Common: 0,
      Rare: 0,
      Epic: 0,
      Legendary: 0,
    };

    // Calculate materials based on adventurer levels and rarity requirements
    // to do poprawki po zmianie rarity na levels wg Bard
    adventurers.forEach((adventurer) => {
      const { level, address } = adventurer;

      if (validRarity('Epic', address, level)) {
        totalMaterials.Common += 32;
        totalMaterials.Rare += 32;
        totalMaterials.Epic += 24;
      } else if (validRarity('Rare', address, level)) {
        totalMaterials.Common += 32;
        totalMaterials.Rare += 32;
      } else if (validRarity('Common', address, level)) {
        totalMaterials.Common += 32;
      }
    });

    // Convert the totals to an array of MaterialNeeded
    return Object.entries(totalMaterials)
      .filter(([, amount]) => amount > 0)
      .map(([rarity, amount]) => ({
        rarity: rarity as RarityType,
        amount,
      }));
  };


  const getFilteredAndSortedAdventurers = () => {
    console.log('Equip w switch1:',equip)
    let filteredAdventurers = adventurers;

    // Apply filtering
    if (!aov) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0x747910b74d2651a06563c3182838eae4120f4277');
    if (!mrdr) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9');
    if (!smol) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0xa7f1462e0ecdeebdee4faf6681148ca96db78777' && adv.address !== '0x17dacad7975960833f374622fad08b90ed67d1b5');
    if (!toe) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0x7480224ec2b98f28cee3740c80940a2f489bf352');
    if (!life) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4');
    if (!kote) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9' && adv.address !== '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d');
   
   
   
    if (onlyReadyForBattle) {
      filteredAdventurers = filteredAdventurers.filter(adv => Number(adv.attackEpoch) !== currentEpoch);
    }


    // Apply sorting
    if (sortConfig !== null) {
      filteredAdventurers = filteredAdventurers.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        switch (sortConfig.key) {
          case 'level':
            aValue = Number(a.level);
            bValue = Number(b.level);
            break;
          case 'bp':
            aValue = Math.floor(a.battlePower / 1000);
            bValue = Math.floor(b.battlePower / 1000);
            break;
          case 'renown':
            aValue = Math.floor(a.renown / 1000);
            bValue = Math.floor(b.renown / 1000);
            break;
          case 'id':
            aValue = Number(a.tokenId);
            bValue = Number(b.tokenId);
            break;
          case 'status': // Sorting by Status
            aValue = Number(a.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0; // Not ready vs. Ready
            bValue = Number(b.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0;
            break;
          
          case 'slots':
            aValue = 0.2*(isSlotOccupied(a.slot1) + isSlotOccupied(a.slot2)+ isSlotOccupied(a.slot3)+ isSlotOccupied(a.slot4)) + isSlotOccupied(a.slot1equip) + isSlotOccupied(a.slot2equip)+ isSlotOccupied(a.slot3equip)+ isSlotOccupied(a.slot4equip);
            bValue = 0.2*(isSlotOccupied(b.slot1) + isSlotOccupied(b.slot2)+ isSlotOccupied(b.slot3)+ isSlotOccupied(b.slot4)) + isSlotOccupied(b.slot1equip) + isSlotOccupied(b.slot2equip)+ isSlotOccupied(b.slot3equip)+ isSlotOccupied(b.slot4equip);
            break;

          case 'ratio':
            aValue = (a.renown + 1) / (
              equip
                ? a.battlePowerEquip + 1
                : a.battlePowerAdjusted !== a.battlePower
                ? a.battlePowerAdjusted + 1
                : a.battlePower + 1
            );
            bValue = (b.renown + 1) / (
              equip
                ? b.battlePowerEquip + 1
                : b.battlePowerAdjusted !== b.battlePower
                ? b.battlePowerAdjusted + 1
                : b.battlePower + 1
            );
            break;

          default:
            aValue = a;
            bValue = b;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredAdventurers;
  };

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Clear selected adventurers after sorting
    onSelectionChange([], equip, armory, image); // Deselect all adventurers

    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setEquip(checked);
    setCalculating(true);
    onEquipChange(checked);
    usedOpponents.clear();
    onSelectionChange([], equip, armory, image); // Clear selections

    const filteredAdventurers = sortedAdventurers; // Using only filtering, order is irrelevant here
    recalculateBattlePairsWithEquip(filteredAdventurers, checked);
  };

  const recalculateBattlePairsWithEquip = (adventurers: AdventurerType[], equip: boolean) => {
    const countRef = { current: 0 };
    const newBattlePairs = adventurers.map(adventurer => {
      const opponent = selectOpponent(mode, adventurer, opponentsData, usedOpponents, lootboxPrices, cryptoPrices, countRef, equip,lpBoostValue || 0);
      if (!opponent) {
        return null;
      }
      const battle = {
        winProbability: calculateWinProbability(adventurer, opponent, equip),
        lootboxChance: calculateLootboxChance(adventurer, opponent, equip),
        lootboxValue: calculateLootBoxValue(adventurer, opponent, equip, lootboxPrices, cryptoPrices),
        animaRewardExpected: calculateAnimaRewardExpected(adventurer, opponent, equip, lpBoostValue || 0),
        usdRewardExpected: calculateUsdRewardExpected(adventurer, opponent, equip, lootboxPrices, cryptoPrices, lpBoostValue || 0),
        renownRewardExpected: calculateRenownRewardExpected(adventurer, opponent, equip)
      };
      return { adventurer, opponent, battle, equip };
    }).filter(pair => pair !== null) as BattlePairType[];
    setBattlePairs(newBattlePairs);
    setCheckedOpponentsCount(countRef.current);
    const battlesFromBattleList: Battles = newBattlePairs.reduce((acc, pair) => {
      const key = `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`;
      acc[key] = {
        adventurer: pair!.adventurer,
        opponent: pair!.opponent,
        results: pair!.battle,
      };
      return acc;
    }, {} as Battles);
    setBattles(battlesFromBattleList);

    const minDelay = 500; // Minimum delay of 1000ms (1 second)
    const maxDelay = 6000; // Maximum delay of 5000ms (5 seconds)
    // Calculate a reasonable delay based on the number of adventurers
   
    const delay = Math.min(minDelay + Number(adventurers.length) * 10, maxDelay); // Max delay of 10 seconds
    console.log('adventurers length:',Number(adventurers.length),' delay:',delay);
    setTimeout(() => {
      setCalculating(false);
    }, delay);
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalculating(true);
    setImage(event.target.checked);
    usedOpponents.clear();
    onSelectionChange([], equip, armory, image); // Clear selections
  };

  const rarityThresholds: Record<string, { rarity: RarityType; level: number }[]> = {
    '0x747910b74d2651a06563c3182838eae4120f4277': [ // AoVs
      { rarity: 'Legendary', level: 36 },
      { rarity: 'Epic', level: 26 },
      { rarity: 'Rare', level: 11 },
      { rarity: 'Common', level: 0 },
    ],
    '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9': [ // MRDRs
      { rarity: 'Rare', level: 11 },
      { rarity: 'Common', level: 0 },
    ],
  };


  const validRarity = (rType: string, address: string, level: number): boolean => {
    const thresholds = rarityThresholds[address];
    
    if (thresholds) {
      // Check if the rarity type meets any threshold for this address
      return thresholds.some(threshold => rType === threshold.rarity && level >= threshold.level);
    }
  
    // Default rule for all other cases
    return rType === 'Common';
  };

  const neededRarity = (address: string, level: number): RarityType => {
    const thresholds = rarityThresholds[address];
  
    if (thresholds) {
      // Find the highest rarity threshold the level qualifies for
      for (const threshold of thresholds) {
        if (level >= threshold.level) {
          return threshold.rarity;
        }
      }
    }
  
    // Default rule for all other cases
    return 'Common';
  };

  const handlePeriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    Object.keys(neededPeripherals).forEach(key => {
      neededPeripherals[key as RarityType].amount = 0;
    });

    setNeededPeripheralsArray([]);
    setMaterialsNeededArray([]);
    setShowTooltip(checked);  // Show tooltip only if checked

    
    if (checked) {
      setOriginalAdventurers([...adventurers]);
      setOriginalBalances({ ...rarityItemBalances });
  
      const filteredAdventurers = getFilteredAndSortedAdventurers();
  
      const updatedAdventurers = filteredAdventurers.map(adv => ({ ...adv }));
      let localBalances = { ...rarityItemBalances };
  
      updatedAdventurers.forEach(adv => {
        const addEquipmentToArmoryIds: number[] = [];
        const addEquipmentToArmoryAmounts: number[] = [];
        let peripheralAdded = false;
  
        ['slot1', 'slot2', 'slot3', 'slot4'].forEach((slot, index) => {
          if (adv[slot as keyof AdventurerType] === 0 && adv[`slot${index + 1}equip` as keyof AdventurerType] === 0) {
            const slotType = ['Head', 'Chest', 'Hand', 'Accessory'][index];
  
            // Define rarity priorities
            const rarityPriority = ['Legendary', 'Epic', 'Rare', 'Common'];
  
            // Try each rarity in the priority order
            let suitablePeripheralId: string | undefined;
            for (const rarity of rarityPriority) {
              suitablePeripheralId = Object.keys(localBalances).find(id => {
                const item = PERIPHERALS[Number(id)];
                return item && 
                  item.attributes.some(attr => attr.trait_type === 'Slot' && attr.value === slotType) &&
                  item.attributes.some(attr => attr.trait_type === 'Rarity' && attr.value === rarity && validRarity(attr.value, adv['address'], adv['level'])) &&
                  localBalances[Number(id)] > 0;
              });
  
              // If a suitable peripheral is found for this rarity, break the loop
              if (suitablePeripheralId) break;
            }
  
            if (suitablePeripheralId) {
              const peripheralId = Number(suitablePeripheralId);
  
              // Safe check to ensure peripheral exists
              const peripheral = PERIPHERALS[peripheralId];
              if (peripheral) {
                (adv as any)[slot] = peripheralId;
                (adv as any)[`${slot}Rarity`] = peripheral.attributes.find(attr => attr.trait_type === 'Rarity')?.value;
                localBalances[peripheralId] -= 1;
  
                if (localBalances[peripheralId] === 0) {
                  delete localBalances[peripheralId];
                }
  
                addEquipmentToArmoryIds.push(peripheralId);
                addEquipmentToArmoryAmounts.push(1);
                peripheralAdded = true;
              }
            }
            else {
              const requiredRarity = neededRarity(adv['address'], adv['level'])
              console.log('needed rarity', requiredRarity)

              if (neededPeripherals[requiredRarity]) {
                neededPeripherals[requiredRarity].amount += 1;
              } else {
                neededPeripherals[requiredRarity] = { rarity: requiredRarity, amount: 1 };
              }
            }
          }
        });
  
        adv.addEquipmentToArmoryIds = addEquipmentToArmoryIds;
        adv.addEquipmentToArmoryAmounts = addEquipmentToArmoryAmounts;
  
        if (peripheralAdded) {
          adv.battlePowerAdjusted = Math.round(calculateBattlePower(adv, false) * 1000);
          adv.battlePowerEquip = Math.round(calculateBattlePower(adv, true) * 1000);
          if (adv.battlePowerAdjusted > adv.battlePowerEquip) adv.battlePowerEquip = adv.battlePowerAdjusted;
        }
      });
  
      localBalances = Object.fromEntries(Object.entries(localBalances).filter(([_, amount]) => amount > 0));
  
      setAdventurers(updatedAdventurers);
      setRarityItemBalances(localBalances);
  
      onArmoryChange(checked);


      const updatedNeededPeripheralsArray = Object.values(neededPeripherals);
      setNeededPeripheralsArray(updatedNeededPeripheralsArray);


      // Calculate materials needed based on neededPeripheralsArray
      const newMaterialsNeeded: Record<RarityType, number> = {
        Common: 0,
        Rare: 0,
        Epic: 0,
        Legendary: 0,
      };

      updatedNeededPeripheralsArray.forEach(peripheral => {
        materialRequirements[peripheral.rarity].forEach(requirement => {
          newMaterialsNeeded[requirement.rarity] += requirement.amount * peripheral.amount;
        });
      });

      // Filter out materials where the amount is 0 and update state
      setMaterialsNeededArray(
        Object.entries(newMaterialsNeeded)
          .filter(([, amount]) => amount > 0)
          .map(([rarity, amount]) => ({ rarity: rarity as RarityType, amount }))
      );

      setShowTooltip(true)


    } else {
      setAdventurers([...originalAdventurers]);
      setRarityItemBalances({ ...originalBalances });
    }
  
    usedOpponents.clear();
    onSelectionChange([], equip, armory, image);
  };
  
  
  

  const handleModeChange = useCallback((newMode: string) => {
    console.log("Balance mode changed to:", newMode);
    setTimeout(() => {
      setCalculating(true);
    }, 0); // Small delay to ensure state update is processed
    usedOpponents.clear();
    setMode(newMode); // Set the new balance value
    onSelectionChange([], equip, armory, image); // Clear selections
  }, [setCalculating, usedOpponents, setMode, onSelectionChange, equip, armory, image]);

  useEffect(() => {
    if (balanceValue !== null) {
      handleModeChange(balanceValue.toFixed(2)); // Recalculate based on the updated balance value
    }
  }, [balanceValue]);

  // Save balanceValue to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('balanceValue', balanceValue.toFixed(2));
  }, [balanceValue]);
  
  // Ensure tempBalanceValue is synced with balanceValue when it changes
  useEffect(() => {
    setTempBalanceValue(balanceValue);
  }, [balanceValue]);

  useEffect(() => {
    const fetchAdventurers = async () => {
      const adv = await getAdventurers(address);
      setAdventurers(adv);
      const calculatedMaterials = calculateMaterialsFor20Days(adv);
      setMaterialsFor20Days(calculatedMaterials);
      console.log("Materials needed for 20 days:",calculatedMaterials);

    };
    fetchAdventurers();
  }, [address]);

  useEffect(() => {
    if (forceRefresh) {
      const fetchAdventurers = async () => {
        const adv = await getAdventurers(address);
        setAdventurers(adv);
        onSelectionChange([], equip, armory, image); // Clear selections and pass equip armory
        usedOpponents.clear(); // Clear used opponents after refreshing adventurers
      };
      fetchAdventurers();
    }
  }, [forceRefresh, address, onSelectionChange, equip, armory, image]);

  const sortedAdventurers = useMemo(() => {
    //console.log('Equip w switch2:',equip)
    if (sortConfig !== null) {
      return [...adventurers].sort((a, b) => {
        let aValue: any;
        let bValue: any;

        switch (sortConfig.key) {
          case 'level':
            aValue = Number(a.level);
            bValue = Number(b.level);
            break;
          case 'bp':
            aValue = Math.floor(a.battlePower / 1000);
            bValue = Math.floor(b.battlePower / 1000);
            break;
          case 'renown':
            aValue = Math.floor(a.renown / 1000);
            bValue = Math.floor(b.renown / 1000);
            break;
          case 'id':
            aValue = Number(a.tokenId);
            bValue = Number(b.tokenId);
            break;
          case 'status': // Sorting by Status
            aValue = Number(a.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0; // Not ready vs. Ready
            bValue = Number(b.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0;
            break;
          case 'slots':
            aValue = 0.2*(isSlotOccupied(a.slot1) + isSlotOccupied(a.slot2)+ isSlotOccupied(a.slot3)+ isSlotOccupied(a.slot4)) + isSlotOccupied(a.slot1equip) + isSlotOccupied(a.slot2equip)+ isSlotOccupied(a.slot3equip)+ isSlotOccupied(a.slot4equip);
            bValue = 0.2*(isSlotOccupied(b.slot1) + isSlotOccupied(b.slot2)+ isSlotOccupied(b.slot3)+ isSlotOccupied(b.slot4)) + isSlotOccupied(b.slot1equip) + isSlotOccupied(b.slot2equip)+ isSlotOccupied(b.slot3equip)+ isSlotOccupied(b.slot4equip);
            break;

          case 'ratio':
            aValue = (Math.floor(a.renown/1000) + 1) / (
              equip
                ? Math.floor(a.battlePowerEquip/1000) + 1
                : a.battlePowerAdjusted !== a.battlePower
                ? Math.floor(a.battlePowerAdjusted/1000) + 1
                : Math.floor(a.battlePower) + 1
            );
            bValue = (Math.floor(b.renown/1000) + 1) / (
              equip
              ? Math.floor(b.battlePowerEquip/1000) + 1
              : b.battlePowerAdjusted !== b.battlePower
              ? Math.floor(b.battlePowerAdjusted/1000) + 1
              : Math.floor(b.battlePower) + 1
            );
            console.log('equip:',equip,'tokenId:',a.tokenId,' aValue:',aValue,' a.renown:',a.renown,' a.battlePower:',a.battlePower,' a.battlePowerEquip:',a.battlePowerEquip,' a.battlePowerAdjusted:',a.battlePowerAdjusted)
            break;
  
          default:
            aValue = a;
            bValue = b;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return adventurers;
  }, [adventurers, sortConfig]);

  const filteredAdventurers = useMemo(() => {
  
    console.log("Adventurers state:", adventurers); // Debugging line
    console.log("Checkbox states - AOV:", aov, "MRDR:", mrdr, "SMOL:", smol, "TOE:", toe, "LIFE:", life, "KOTE:", kote); // Debugging line
    
    const filtered = sortedAdventurers.filter(adv => {
      if (!aov && adv.address === '0x747910b74d2651a06563c3182838eae4120f4277') return false;
      if (!mrdr && adv.address === '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9') return false;
      if (!smol && (adv.address === '0xa7f1462e0ecdeebdee4faf6681148ca96db78777' || adv.address === '0x17dacad7975960833f374622fad08b90ed67d1b5')) return false;
      if (!toe && adv.address === '0x7480224ec2b98f28cee3740c80940a2f489bf352') return false;
      if (!life && adv.address === '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4') return false;
      if (!kote && (adv.address === '0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9' || adv.address === '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d')) return false;
      
      // Update this logic for "Only ready for battle" filter
      if (onlyReadyForBattle && Number(adv.attackEpoch) === currentEpoch) return false;
  
      return true;
    });
  
    console.log("Filtered adventurers:", filtered); // Debugging line
    return filtered;
  }, [sortedAdventurers, aov, mrdr, smol, toe, life, kote, onlyReadyForBattle, currentEpoch]);
  

  useEffect(() => {
    usedOpponents.clear(); // Clear used opponents before recalculating
    recalculateBattlePairsWithEquip(filteredAdventurers, equip);
  }, [filteredAdventurers, mode, opponentsData, lootboxPrices, cryptoPrices, equip, image]);

  const handleRowClick = useCallback((id: string) => {
    const adventurer = battlePairs.find(pair => `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}` === id)?.adventurer;
    if (adventurer && adventurer.attackEpoch === Math.floor(Date.now() / (24 * 60 * 60 * 1000))) {
      return;
    }
    const updatedSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter(selectedId => selectedId !== id)
      : [...selectedIds, id];
    onSelectionChange(updatedSelectedIds, equip, armory, image); // Przekazywanie equip
  }, [selectedIds, battlePairs, onSelectionChange, equip, armory, image]);

  const handleSelectAll = useCallback(() => {
    const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
    const validPairs = battlePairs.filter(pair => Number(pair!.adventurer.attackEpoch) !== Number(currentEpoch));
    const validIds = validPairs.map(pair => `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`);
    const updatedSelectedIds = selectedIds.length === validIds.length ? [] : validIds;
    
    onSelectionChange(updatedSelectedIds, equip, armory, image); // Przekazywanie equip
  }, [selectedIds, battlePairs, onSelectionChange, equip, armory, image]);

  const handleSelectTop50 = useCallback(() => {
    const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
    
    // First, filter the valid pairs like in handleSelectAll
    const validPairs = battlePairs.filter(pair => Number(pair!.adventurer.attackEpoch) !== Number(currentEpoch));
    
    // Map valid pairs to get the valid adventurer IDs
    const validIds = validPairs.map(pair => `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`);
  
    // Select the top 50 valid adventurers
    const top50Ids = validIds.slice(0, 50);
    
    // Check if the top 50 are already selected or not, then update the selection accordingly
    const updatedSelectedIds = selectedIds.length === top50Ids.length ? [] : top50Ids;
  
    onSelectionChange(updatedSelectedIds, equip, armory, image);
  }, [selectedIds, battlePairs, onSelectionChange, equip, armory, image]);


  // Update the temp value as the user drags the slider
  const handleBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setTempBalanceValue(newValue); // Update the temporary value without re-rendering
  };


  // Finalize the value when the user releases the mouse
  const handleSliderRelease = () => {
    setbalanceValue(tempBalanceValue); // Only set the main balanceValue on release
  };

  

  return (
    <div className="battle-list-wrapper">
      <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
        <div>Opponents in the arena: {checkedOpponentsCount}</div>
        <div><button className="toggle-all" onClick={handleSelectAll}>Toggle all</button></div>
        <div><button className="toggle-all" onClick={handleSelectTop50}>Top Batch</button></div>
        <div className="equip-container">
          <div className="equip-wrapper">
            <label className="equip-label">
              Equip if available
              <input id="equip" type="checkbox" onChange={handleCheckboxChange} checked={equip} className="equip-checkbox" />
              {/* Tooltip for "Equip if available" */}
              <div className="equip-tooltip-text">
                <strong>Full set of materials needed for 20 days of battles:</strong>
                <ul>
                {materialsFor20Days.map((material, index) => (
                  <li key={index}>
                    {material.rarity}: {material.amount}
                  </li>
                ))}
              </ul>
              </div>
            </label>
          </div>
        </div>

        <div className="equip-container">
          <label className="peri-label">
            Use peripherals from wallet
            <input id="peri" type="checkbox" onChange={handlePeriChange} className="equip-checkbox" />
            {/* Tooltip-like section for neededPeripheralsArray */}
            {showTooltip && materialsNeededArray.some(material => material.amount > 0) &&(
              <div className="peri-tooltip-text">
               <strong>For fully equipping your adventurers,<br />for one set,<br />you need to craft peripherals:</strong>

                <ul>
                  {neededPeripheralsArray
                    .filter(peripheral => peripheral.amount > 0)
                    .map((peripheral, index) => (
                      <li key={index}>
                        {peripheral.rarity} : {peripheral.amount}
                      </li>
                    ))}
                </ul>
                <strong>Total amount of materials needed:</strong>
                <ul>
                  {materialsNeededArray.map((material, index) => (
                    <li key={index}>
                      {material.rarity} : {material.amount}
                    </li>
                  ))}
                </ul>
                <strong>Check your inventory to see if you have enough</strong>
              </div>
            )}
          </label>
        </div>


        <div className="equip-container">
          <label className="equip-label">
            Show avatars
            <input id="avatar" type="checkbox" onChange={handleAvatarChange} className="equip-checkbox" />
          </label>
        </div>
        <div className="slider-container" >&nbsp;
        </div>
        <div className="equip-container">
        <label className="tooltip-label" >
          Balance: {tempBalanceValue.toFixed(2)}
            <div className="tooltip-text">
            0.00 ~ max Lootbox Chance <br /> 1.00 ~ max USD Profit <br /> 2.00 ~ max Renown
            </div>
            <div className="tooltip-container">
              <input
                id="balance"
                type="range"
                min="0.00"
                max="2.00"
                step="0.05"
                value={balanceValue}
                onChange={handleBalanceChange} // Update the slider's value
                onMouseUp={handleSliderRelease} // Trigger action when user releases the slider
                onTouchEnd={handleSliderRelease} // Mobile touch event support
                className="gauge-slider"
              />
              
            </div>
            
          </label>
        </div>


      </div>
      <BattleTable 
        battlePairs={battlePairs} 
        selectedIds={selectedIds} 
        onRowClick={handleRowClick} 
        equip={equip}
        armory={armory}
        image={image}
        handleSelectAll={handleSelectAll}
        checkedOpponentsCount={checkedOpponentsCount}
        sortConfig={sortConfig}
        requestSort={requestSort}
        setBattleTableRendered={setBattleTableRendered} // Pass the prop
      />
      <style jsx>{`
        .battle-list-wrapper {
          position: relative;
          z-index: 10; /* Ustawienie niższego z-index */
        }
        .toggle-all {
          height: 24px;
          background-color: #666;
          color: white;
          border-radius: 4px;
          cursor: pointer;
          width: 80px;
        }

        .toggle-all:hover {
          background-color: #555;
        }

        .reload {
          background-color: #666;
          color: white;
          cursor: pointer;
          font-size: 24px;
        }
        
        .treload:hover {
          background-color: #555;
        }

        .equip-container {
          display: flex;
          align-items: center;
        }
        
        .slider-container {
          display: flex;
          align-items: center;
          width: 10px;
        }
       
        .equip-label {
          display: flex;
          align-items: center;
          font-size: 14px;
          background-color: #666;
          color: white;
          padding: 2px 8px;
          border-radius: 4px;
          cursor: pointer;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-right: solid 2px black;
          border-bottom: solid 2px black;
          border-left: solid 2px #555;
          border-top: solid 2px #555;
          
        }


       
        .equip-checkbox {
          transform: scale(1.5);
          margin-left: 8px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-color: #66cc66; /* Ustawienie tła na taki sam odcień zielonego */
          border: 2px solid #555;
          border-radius: 4px;
          width: 16px; /* Dostosuj rozmiar */
          height: 16px; /* Dostosuj rozmiar */
          cursor: pointer;
          position: relative;
        }
        .equip-checkbox:checked::after {
          content: '✓'; /* Znak zaznaczenia */
          color: white; /* Kolor znaku zaznaczenia */
          font-size: 10px; /* Zmniejszenie rozmiaru znaku */
          position: absolute;
          top: -2px;
          left: 2px;
        }
        .equip-checkbox:checked {
          background-color: #66cc66; /* Ustawienie tła na taki sam odcień zielonego po zaznaczeniu */
        }


         .equip-tooltip-text {
            visibility: hidden; /* Hidden by default */
            background-color: #c3c356; /* Match tooltip style */
            color: black;
            text-align: left;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            top: 100%; /* Align below the label */
            left:0; /* Center align */
            
            transform: translateX(-50%);
            width: auto; /* Adjust size automatically */
            font-size: 11px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
          }
        
          .equip-wrapper {
            position: relative; /* Ensure the tooltip is positioned relative to this wrapper */
          }

        .equip-wrapper:hover .equip-tooltip-text {
            visibility: visible; /* Show tooltip on hover */
            opacity: 1;
          }

        .peri-label {
          display: flex;
          align-items: center;
          font-size: 14px;
          background-color: #666;
          color: white;
          padding: 2px 8px;
          border-radius: 4px;
          cursor: pointer;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-right: solid 2px black;
          border-bottom: solid 2px black;
          border-left: solid 2px #555;
          border-top: solid 2px #555;
          position: relative; /* Make the label the reference point for the tooltip */
          
        }

        


        .gauge-slider {
          appearance: none;
          width: 190px;
          height: 8px;
          background: #444;
          border-radius: 5px;
          outline: none;
          cursor: pointer;
          transition: background 0.15s ease-in-out;
        }

        .gauge-slider::-webkit-slider-thumb {
          appearance: none;
          width: 16px;
          height: 16px;
          background: #66cc66;
          border-radius: 50%;
          border: 2px solid #555;
          cursor: pointer;
        }

        .gauge-slider::-moz-range-thumb {
          width: 16px;
          height: 16px;
          background: #66cc66;
          border-radius: 50%;
          border: 2px solid #555;
          cursor: pointer;
        }

        .tooltip-container {
          position: relative;
          display: inline-block;
        }

        .tooltip-container:hover .tooltip-text {
          visibility: visible;
        }

        .tooltip-text {
          visibility: hidden;
          background-color: #c3c356;
          color: black;
          text-align: left;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
         
          left: 88%;
          bottom: 100%;
          transform: translateX(-50%);
          width: 200px;
          font-size: 11px;
          opacity: 0;
          transition: opacity 0.3s;
        }

        .peri-tooltip-text {
          visibility: hidden;
          background-color: #c3c356;
          color: black;
          text-align: left;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
         
          top: 100%; /* Align it below the label */
          left: 50%; /* Align it to the start of the label */
          width: 100%; 
          transform: translateX(-50%);
          
          font-size: 11px;
          opacity: 0;
          transition: opacity 0.3s;
        }

        .peri-tooltip-text ul {
          padding: 0;
          margin: 0;
        }

        .peri-tooltip-text li {
          padding: 2px 0; /* Adjust padding as needed */
          margin: 0;
          line-height: 1.2; /* Reduces the space between lines */
          font-size: 12px; /* Adjust font size as needed */
        }
        
        .tooltip-label{

        width: 120px; 
        }

        .tooltip-label:hover .tooltip-text {
          visibility: visible;
          opacity: 1;
        }



        /* Show tooltip when hovering over the label */
        .peri-label:hover .peri-tooltip-text {
          visibility: visible;
          opacity: 1;
        }

      `}</style>
    </div>
  );
};

export default React.memo(BattleList);





























  
  


