// utils.ts
import { useEffect, useState , useRef, ReactNode} from 'react';
import { ethers } from 'ethers';
import { AdventurerType, OpponentType } from '../types/adventurer';
//import { ModeType } from '../components/BattleList';
import multiLpBattleBoostAbi from '../../../eth-sdk/abis/MuliLpBattleBoostAbi.json';
import { animaBurnPercentage } from '../../../pages/index'; // Import the global variable

const MULTI_LP_BATTLE_BOOST_ADDRESS = '0xADB431B7D30383aE47f523dDba232bA803957084';

  

export function currentEpoch(){
  return Math.floor(Date.now() / (24 * 60 * 60 * 1000));
  }

  export const getMultiplier = (adventurer: AdventurerType): number => {
    let mp=1;
    if (adventurer.address === '0x747910b74d2651a06563c3182838eae4120f4277') { //AoV
      mp = 5;
    }
    //dodajemy tę poprawkę ze względu na zmianę skalowania współczynnika MRDRs
    /*
    else if (adventurer.address === '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9'){ //MRDR
      if (adventuer.level >= 26) mp = 3;
      else if (adventurer.level >= 16) mp = 2.5;
      else if (adventurer.level >= 11) mp=2;
      else if (adventurer.level >= 7) mp=1.5;
    }
    */
    return mp;
  };
  
  
  export function avgLPBoostPercentage(address: string, adventurers: AdventurerType[]): number {
    // normalny reward
    let LPB = useLpBoost(address);
    let sumLP = 0;
    let sumTotal = 0;

    adventurers.forEach((adv) => {
      let Multiplier = getMultiplier(adv);
      sumTotal += (0.3 * Multiplier + Number(LPB)) * adv.renown / 100;
      sumLP += Number(LPB) * adv.renown / 100;
    });
  
    return sumLP / sumTotal;
  }

  export async function fetchAnimaBase(address: string): Promise<number> {
    const provider = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
    const contract = new ethers.Contract(MULTI_LP_BATTLE_BOOST_ADDRESS, multiLpBattleBoostAbi, provider);
  
    try {
      const animaBase = await contract.getAnimaBase(address);
  
      console.log('Anima Base (raw):', animaBase.toString());
      const animaBaseFormatted = parseFloat(ethers.utils.formatUnits(animaBase, 18));
      console.log('Anima Base (formatted):', animaBaseFormatted);
  
      return animaBaseFormatted;
    } catch (error) {
      console.error('Error fetching Anima Base:', error);
      throw error;
    }
  }
  
  export async function lpBoostBase(address: string): Promise<number> {
    const animaBase = await fetchAnimaBase(address);
    return animaBase;
  }
  
  export function useLpBoost(address: string): number | null {
    const [lpBoostValue, setLpBoostValue] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const fetched = useRef(false);
  
    useEffect(() => {
      async function getLpBoost() {
        try {
          const value = await lpBoostBase(address);
          setLpBoostValue(value);
          setError(null);
        } catch (error) {
          console.error('Error fetching LP Boost:', error);
          setError('Failed to fetch LP Boost');
        }
      }
  
      if (!fetched.current) {
        fetched.current = true;
        getLpBoost();
      }
    }, [address]);
  
    if (error) {
      console.error(error);
    }
  
    return lpBoostValue;
  }
  
  




export const calculateSimilar = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean): number => {
  let aBP = Number(adventurer.battlePowerAdjusted)+1;
  if (equip) aBP=Number(adventurer.battlePowerEquip)+1;

  const bBP = Number(opponent.battlePower);
  const delta = 1000000 - Math.abs(aBP - bBP)+Number(opponent.renown)/1000;
  return delta;
};

export const calculateWeakest = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean): number => {
  let aBP = Number(adventurer.battlePowerAdjusted)+1;
  if (equip) aBP=Number(adventurer.battlePowerEquip)+1;
  const bBP = Number(opponent.battlePower) + 1;
  const delta = aBP - bBP;
  return delta;
};

export const calculateStrongest = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean): number => {
  let aBP = Number(adventurer.battlePowerAdjusted)+1;
  if (equip) aBP=Number(adventurer.battlePowerEquip)+1;
  const bBP = Number(opponent.battlePower) + 1;
  const delta = bBP - aBP;
  return delta;
};

export const calculateWinProbability = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean): number => {
  let aBP = Number(adventurer.battlePowerAdjusted)+1;
  if (equip) aBP=Number(adventurer.battlePowerEquip)+1;
  const bBP = Number(opponent.battlePower) + 1;
  let winProb = aBP / (aBP + bBP);
  if (winProb<0 || winProb>1) winProb=0
  return parseFloat(winProb.toFixed(4));
};

export const calculateLootboxChance = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean): number => {
  const ONE_HUNDRED = 100000;
  const lootBoxMaxDropRate = 30000;

  // Use the global animaBurnPercentage
  const lootBoxTotalChance = 100000;
  const _probability = (1 - calculateWinProbability(adventurer, opponent, equip)) * (lootBoxMaxDropRate / ONE_HUNDRED)*(1+(animaBurnPercentage || 0));
  return _probability;
};

export const calculateLootBoxValue = (adventurer: AdventurerType, opponent: OpponentType , equip: boolean, lootboxPrices: any, cryptoPrices: any): number => {
  const level = adventurer.level;
  const chanceCommon = 0.675;
  let chanceRare = 0.225; if (level < 5) chanceRare = 0;
  let chanceEpic = 0.075; if (level < 10) chanceEpic = 0;
  let chanceLegendary = 0.025; if (level < 15) chanceLegendary = 0;

  const valueCommon = lootboxPrices.common / 1e18;
  let valueRare = lootboxPrices.rare / 1e18;
  const valueEpic = lootboxPrices.epic / 1e18;
  const valueLegendary = lootboxPrices.legendary / 1e18;

  const lootBoxChance = calculateLootboxChance(adventurer, opponent, equip);

  const expectedValue = lootBoxChance * ((chanceCommon * valueCommon) + (chanceRare * valueRare) + (chanceEpic * valueEpic) + (chanceLegendary * valueLegendary));
  return parseFloat(expectedValue.toFixed(4));
};

export const calculateAnimaRewardExpected = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean, LPBoost: number): number => {
  const afterBattleRenown = Number(adventurer.renown) / 1000 + calculateRenownRewardExpected(adventurer, opponent,equip);
  let aovMultiplier = getMultiplier(adventurer);
  let finalAnima=0.3*aovMultiplier * afterBattleRenown / 100;
  finalAnima+=LPBoost*afterBattleRenown/100;
  return parseFloat((finalAnima).toFixed(4));
};
/*
export const calculateAnimaBaseRewardExpected = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean): number => {
  const afterBattleRenown = Number(adventurer.renown) / 1000 + calculateRenownRewardExpected(adventurer, opponent,equip);
  let aovMultiplier = 1;
  if (adventurer.address === '0x747910b74d2651a06563c3182838eae4120f4277') aovMultiplier = 5;
  let finalAnima=0.3*aovMultiplier * afterBattleRenown / 100;
  //let LPboost=0.25;
  //finalAnima+=LPboost*afterBattleRenown/100;
  return parseFloat((finalAnima).toFixed(4));
};
*/
export const calculateUsdRewardExpected = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean, lootboxPrices: any, cryptoPrices: any, LPBoost: number): number => {
  const lootBoxValue = calculateLootBoxValue(adventurer, opponent, equip,lootboxPrices, cryptoPrices);
  const animaReward = calculateAnimaRewardExpected(adventurer, opponent, equip, LPBoost);
  const cryptoMagic = cryptoPrices.magic.usd;
  const cryptoAnima = cryptoPrices.anima.usd;

  const usdReward = cryptoMagic * lootBoxValue + cryptoAnima * animaReward;

  return parseFloat(usdReward.toFixed(4));
};


// Stablicowana funkcja sinus dla x w przedziale [0, 2] z krokiem 0.05
const step = 0.05;
const lookupTable: number[] = [];

// Wypełniamy tablicę wynikami funkcji customSinusFunction dla wartości x od 0 do 2
for (let i = 0; i <= 2; i += step) {
    lookupTable.push(Math.sin((Math.PI / 2) * (i - 1)) + 1);
}

// Funkcja zwracająca przybliżoną wartość na podstawie tablicy
function fastCustomSinus(x: number): number {
    const index = Math.round(x*20);
    return lookupTable[index] !== undefined ? lookupTable[index] : sinusoidalFunction(x);
}


function sinusoidalFunction(x: number): number {
  return Math.sin((Math.PI / 2) * (x - 1)) + 1;
}

export const calculateCustomModeExpected = (adventurer: AdventurerType, opponent: OpponentType, equip: boolean, lootboxPrices: any, cryptoPrices: any, LPBoost: number, customValue: number): number => {
  let cF=customValue;
  const lootBoxValue = calculateLootBoxValue(adventurer, opponent, equip,lootboxPrices, cryptoPrices);
  const animaReward = calculateAnimaRewardExpected(adventurer, opponent, equip, LPBoost);
  const cryptoMagic = cryptoPrices.magic.usd;
  const cryptoAnima = cryptoPrices.anima.usd;

  const result = (2-fastCustomSinus(cF))*cryptoMagic * lootBoxValue + fastCustomSinus(cF)* cryptoAnima * animaReward;
  //const result = ((2-Math.sqrt(2*cF))*cryptoMagic * lootBoxValue + Math.sqrt(cF)* cryptoAnima * animaReward);


  return parseFloat(result.toFixed(4));
};

export const calculateRenownRewardExpected = (adventurer: AdventurerType, opponent: OpponentType,equip: boolean): number => {
  const K_BASELINE = 32;

 
  let calcBase = K_BASELINE * adventurer.level ;
  const aRenown = Math.floor(Number(adventurer.renown)/1000) + 1;
  const bRenown = Math.floor(Number(opponent.renown)/1000) + 1;
  let aBP = Math.floor(Number(adventurer.battlePowerAdjusted)/1000)+1;
  if (equip) aBP = Math.floor(Number(adventurer.battlePowerEquip)/1000)+1;

  const bBP = Math.floor(Number(opponent.battlePower)/1000) + 1;

  const minRenown = Math.min(aRenown, bRenown);
  const maxRenown = Math.max(aRenown, bRenown);
  if (minRenown < maxRenown / 10) {
    calcBase = calcBase / 5;
  } else if (minRenown < maxRenown / 5) {
    const range = maxRenown / 10;
    const diff = maxRenown / 5 - minRenown;
    calcBase = calcBase - ((calcBase * diff) / range) / 5;
  }
  const calcRenownWinProbability = aRenown / (aRenown + bRenown);

  const wonRenown = calcBase * (1 - calcRenownWinProbability) ;
  const loseRenown = calcBase * (-calcRenownWinProbability) ;
  const calcWinProbability =  aBP / (aBP + bBP);
  const wonBitwa =  (calcWinProbability * wonRenown + (1 - calcWinProbability) * loseRenown) ;

  return parseFloat(wonBitwa.toFixed(4));
};

export const selectOpponent = (
  mode: string,
  myAdventurer: AdventurerType,
  opponents: OpponentType[],
  usedOpponents: Set<string>,
  lootboxPrices: any,
  cryptoPrices: any,
  countRef: { current: number },
  equip: boolean,
  LPB: number
): OpponentType | undefined => {

  let adventurer={...myAdventurer};

  if (equip) adventurer.battlePower=myAdventurer.battlePowerEquip;
  else adventurer.battlePower=myAdventurer.battlePowerAdjusted;
  console.log('equip',equip,'adventurer id:', adventurer.tokenId, 'adventurer battle Power:',adventurer.battlePower, 'Equip:',adventurer.battlePowerEquip, ' adjusted:',adventurer.battlePowerAdjusted)

  if (opponents.length === 0) {
    return undefined;
  }

  let selectedOpponent: OpponentType | undefined;
  let maxMetric = -Infinity;
  let opponentsChecked = 0;

  const filterValidOpponents = (opponentList: OpponentType[]) => {
    return opponentList.filter(opponent => {
      const key = `${opponent.address}-${opponent.tokenId}`;
      return !usedOpponents.has(key) && opponent.battlePower > 0 && opponent.renown > 0;
    });
  };

  const validOpponents = filterValidOpponents(opponents);
  countRef.current = opponents.length;
  validOpponents.forEach(opponent => {
    const metric = calculateCustomModeExpected(adventurer, opponent, equip, lootboxPrices, cryptoPrices, LPB, Number(mode));
    opponentsChecked++;
    if (metric > maxMetric) {
      maxMetric = metric;
      selectedOpponent = opponent;
    }
  });


  if (selectedOpponent) {
    usedOpponents.add(`${selectedOpponent.address}-${selectedOpponent.tokenId}`);
  }

  console.log(`Number of opponents checked: ${opponentsChecked}`);

  return selectedOpponent;
};


export const getAdventurerInfo = (address: string, archetype: string) => {
  let imageUrl = '';
  let advType = '';

  switch (address.toLowerCase()) {
    case '0x747910b74d2651a06563c3182838eae4120f4277':
      imageUrl = `https://app.realmverse.gg/assets/adventurers/${archetype}.webp`;
      advType = 'AoV';
      break;
    case '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9':
      imageUrl = `https://app.realmverse.gg/assets/marauders/${archetype}.jpg`;
      advType = 'MRDR';
      break;
    case '0xa7f1462e0ecdeebdee4faf6681148ca96db78777':
      imageUrl = 'https://djmahssgw62sw.cloudfront.net/general/0xc58e1ac51dd3d194c1f243c4aa5f90904789de8a918e67aba3fd1bffcd38fc2b.svg';
      advType = 'Smol';
      break;
    case '0x17dacad7975960833f374622fad08b90ed67d1b5':
      imageUrl = 'https://djmahssgw62sw.cloudfront.net/general/0x97d57eda862179bb49ffdcd86e38ec2df7459752b8c6cf21c82c7190020187f6.png'
      advType = 'Smol'
      break;
    case '0x7480224ec2b98f28cee3740c80940a2f489bf352':
      imageUrl = 'https://djmahssgw62sw.cloudfront.net/general/0x24f4a94e7b11d9619d36e00a6f88a1725cfd791d38be7710b8927ec70c130f95.png';
      advType = 'ToE';
      break;
    case '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4':
      imageUrl = 'https://djmahssgw62sw.cloudfront.net/general/0x3dc60e350f382299aafa9ab86030e15df41b977a6c0aa82fc6ef2df4e5b449f4.png';
      advType = 'Life';
      break;
    case '0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9':
      imageUrl = 'https://djmahssgw62sw.cloudfront.net/general/0x7e88e411cdd785272fc2a9025e793ce0030e651461a3f5ffee1af7fd22eb0d3d.png';
      advType = 'KOTE';
      break;
    case '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d':
      imageUrl = 'https://djmahssgw62sw.cloudfront.net/general/0x7e88e411cdd785272fc2a9025e793ce0030e651461a3f5ffee1af7fd22eb0d3d.png';
      advType = 'KOTE';
      break;
    default:
      advType = 'other';
      break;
  }

  return { imageUrl, advType };
};

export const getSlotIcons = (adventurer: any, equip: boolean): ReactNode => {
  const slots = [
    (adventurer.slot1 == 0 && adventurer.slot1equip == 0) ? '🟥' : (Number(adventurer.slot1) !== 0 && adventurer.slot1 !== adventurer.slot1equip ? '🟦' : (Number(adventurer.slot1) !== 0 ? '🟩' : (equip && Number(adventurer.slot1equip) !== 0 ? '🟨' : '⬛'))),
    (adventurer.slot2 == 0 && adventurer.slot2equip == 0) ? '🟥' : (Number(adventurer.slot2) !== 0 && adventurer.slot2 !== adventurer.slot2equip ? '🟦' : (Number(adventurer.slot2) !== 0 ? '🟩' : (equip && Number(adventurer.slot2equip) !== 0 ? '🟨' : '⬛'))),
    (adventurer.slot3 == 0 && adventurer.slot3equip == 0) ? '🟥' : (Number(adventurer.slot3) !== 0 && adventurer.slot3 !== adventurer.slot3equip ? '🟦' : (Number(adventurer.slot3) !== 0 ? '🟩' : (equip && Number(adventurer.slot3equip) !== 0 ? '🟨' : '⬛'))),
    (adventurer.slot4 == 0 && adventurer.slot4equip == 0) ? '🟥' : (Number(adventurer.slot4) !== 0 && adventurer.slot4 !== adventurer.slot4equip ? '🟦' : (Number(adventurer.slot4) !== 0 ? '🟩' : (equip && Number(adventurer.slot4equip) !== 0 ? '🟨' : '⬛')))
  ];

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '2px' }}>
      {slots.map((slot, index) => (
        <span key={index} style={{ fontSize: '12px', lineHeight: '1' }}>{slot}</span>
      ))}
    </div>
  );
};


// Funkcje pomocnicze
let renownSum = 0;

export const setRenownSum = (value: number): void => {
  renownSum = value;
};

export const getRenownSum = (): number => {
  return renownSum;
};


